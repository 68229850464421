/* critters:include start */

@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

$app-font-family: Open Sans;
$app-min-width: 240px;

html{
	height: 100%;
	min-width: $app-min-width;
	color-scheme: light dark;
	@include mat.theme((
		color: mat.$green-palette,
		typography: $app-font-family,
		density: 0
	));
}

html.app-dark-theme{color-scheme: dark;}

html.app-light-theme{color-scheme: light;}

body{
	margin: 0;
	height: 100%;
	min-width: $app-min-width;
	font-family: $app-font-family, sans-serif;
}

a{
	white-space: nowrap;
	cursor: pointer;
	font-weight: bold;
	color: var(--mat-sys-primary)
}

a.wrapped{white-space: normal;}

a.no-decoration{text-decoration: none;}

a.white-space-normal{white-space: normal;}

.mat-sidenav-container{height: 100%;}

.app-elevation0{box-shadow: var(--mat-sys-level0) !important;}
.app-elevation1{box-shadow: var(--mat-sys-level1) !important;}
.app-elevation2{box-shadow: var(--mat-sys-level2) !important;}
.app-elevation3{box-shadow: var(--mat-sys-level3) !important;}
.app-elevation4{box-shadow: var(--mat-sys-level4) !important;}
.app-elevation5{box-shadow: var(--mat-sys-level5) !important;}

.app-sys-background{
	background: var(--mat-sys-background) !important;
}
.app-surface-dim{
	background: var(--mat-sys-surface-dim) !important;
	color: var(--mat-sys-on-surface) !important;
}
.app-surface-bright{
	background: var(--mat-sys-surface-bright) !important;
	color: var(--mat-sys-on-surface) !important;
}
.app-surface-container-lowest{
	background: var(--mat-sys-surface-container-lowest) !important;
	color: var(--mat-sys-on-surface) !important;
}
.app-surface-container-low{
	background: var(--mat-sys-surface-container-low) !important;
	color: var(--mat-sys-on-surface) !important;
}
.app-surface-container{
	background: var(--mat-sys-surface-container) !important;
	color: var(--mat-sys-on-surface) !important;
}
.app-surface-container-high{
	background: var(--mat-sys-surface-container-high) !important;
	color: var(--mat-sys-on-surface) !important;
}
.app-surface-container-highest{
	background: var(--mat-sys-surface-container-highest) !important;
	color: var(--mat-sys-on-surface) !important;
}

.app-12{font-size: 12px !important;}

.app-14{font-size: 14px !important;}

.app-16{font-size: 16px !important;}

.app-18{font-size: 18px !important;}

.app-20{font-size: 20px !important;}

.app-24{font-size: 24px !important;}

.app-30{font-size: 30px !important;}

.app-36{font-size: 36px !important;}

.app-48{font-size: 48px !important;}

.mat-icon svg{
	vertical-align: top !important;
}

.app-12.mat-icon,
.app-12 .mat-icon,
.app-12 .mat-icon svg{
	font-size: 12px;height: 12px;width: 12px;
}

.app-14.mat-icon,
.app-14 .mat-icon,
.app-14 .mat-icon svg{
	font-size: 14px;height: 14px;width: 14px;
}

.app-16.mat-icon,
.app-16 .mat-icon,
.app-16 .mat-icon svg{
	font-size: 16px;height: 16px;width: 16px;
}

.app-18.mat-icon,
.app-18 .mat-icon,
.app-18 .mat-icon svg{
	font-size: 18px;height: 18px;width: 18px;
}

.app-20.mat-icon,
.app-20 .mat-icon,
.app-20 .mat-icon svg{
	font-size: 20px;height: 20px;width: 20px;
}

.app-24.mat-icon,
.app-24 .mat-icon,
.app-24 .mat-icon svg{
	font-size: 24px;height: 24px;width: 24px;
}

.app-30.mat-icon,
.app-30 .mat-icon,
.app-30 .mat-icon svg{
	font-size: 30px;height: 30px;width: 30px;
}

.app-36.mat-icon,
.app-36 .mat-icon,
.app-36 .mat-icon svg{
	font-size: 36px;height: 36px;width: 36px;
}

.app-48.mat-icon,
.app-48 .mat-icon,
.app-48 .mat-icon svg{
	font-size: 48px;height: 48px;width: 48px;
}

.app-150.mat-icon,
.app-150 .mat-icon,
.app-150 .mat-icon svg{
	font-size: 150px;height: 150px;width: 150px;
}

.app-primary{
	color: var(--mat-sys-primary) !important;
	background: var(--mat-sys-background) !important;
}

.app-secondary{
	color: var(--mat-sys-secondary) !important;
	background: var(--mat-sys-background) !important;
}

.app-error{
	color: var(--mat-sys-error) !important;
	background: var(--mat-sys-background) !important;
}

.app-primary-color{color: var(--mat-sys-primary) !important;}

.app-secondary-color{color: var(--mat-sys-secondary) !important;}

.app-error-color{color: var(--mat-sys-error) !important;}

.app-primary-background{
	color: var(--mat-sys-background) !important;
	background: var(--mat-sys-primary) !important;
}

.app-secondary-background{
	color: var(--mat-sys-background) !important;
	background: var(--mat-sys-secondary) !important;
}

.app-error-background{
	color: var(--mat-sys-background) !important;
	background: var(--mat-sys-error) !important;
}

.app-pointer{cursor: pointer !important;}

.dialog-responsive{width: 60%;}

.tab-active.mdc-list-item{
	background: var(--mat-sys-surface-container) !important;
	color: var(--mat-sys-on-surface) !important;
}

.tab-active .mdc-list-item__primary-text{
	color: var(--mat-sys-primary) !important;
}

.app-dark-theme ckeditor{
	color: rgba(0, 0, 0, 0.87) !important;
}

.app-text-align-right{
	text-align: right;
}

.app-text-align-left{
	text-align: left;
}

.app-text-align-justify{
	text-align: justify;
}

.stretch-badge > .mat-badge-content{
	width: max-content !important;
	border-radius: 20px !important;
	padding: 0 5px !important;
}

.stretch-badge.cart-item-badge{
	padding-right: 10px;
}

.stretch-badge.product-price-badge{
	padding-right: 70px;
}

.discount-chip{
	text-decoration: line-through;
	font-style: italic;
	color: var(--mat-sys-error);
}

.file-upload-drop-zone{}

.file-upload-drop-zone-content{position: relative;}

.file-upload-drop-zone .panel-glass{
	width: 100%;
	height: 100%;
	backdrop-filter: blur(2px) opacity(1) sepia(100%);
	position: absolute;
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration-style: wavy;
	text-shadow: 0 0 10px black;
	color: white;
}

.ngx-file-drop__drop-zone--over .panel-glass{
	z-index: 11 !important;
}

.spacer{
	flex: 1 1 auto;
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////

//XXSmall
@media (max-width: 319.98px){
	.dialog-responsive{
		width: 100% !important;
		max-width: 100% !important;
		min-width: 100% !important;
	}
	mat-form-field{
		max-width: 100%;
	}
}

//XSmall
@media (min-width: 320px) and (max-width: 599.98px){
	.dialog-responsive{width: 90%;}
	mat-form-field{
		max-width: 100%;
	}
}

//Small
@media (min-width: 600px) and (max-width: 959.98px){
	.dialog-responsive{width: 60%;}
}

//Medium
@media (min-width: 960px) and (max-width: 1279.98px){
	.dialog-responsive{width: 40%;}
}

//Large
@media (min-width: 1280px) and (max-width: 1919.98px){
	.dialog-responsive{width: 40%;}
}

//XLarge
@media (min-width: 1920px){
	.dialog-responsive{width: 20%;}
}


/* critters:include end */




